export type UNITS =
  | 'foot'
  | 'kilometer'
  | 'meter'
  | 'gallon'
  | 'liter'
  | 'kilogram'
  | 'pound'
  | 'mile'
  | 'day'
  | 'week'
  | 'month';

const unitsMap = {
  foot: 'ft',
  kilometer: 'km',
  meter: 'm',
  gallon: 'gal',
  liter: 'l',
  kilogram: 'kg',
  pound: 'lb',
  mile: 'mi',
  day: 'd',
  week: 'wk',
  month: 'mo',
};

export const getShortUnit = (unit: UNITS) => unitsMap[unit] || unit;

export type WEIGHTS = Extract<UNITS, 'kilogram' | 'pound'>;

export type LENGTHS = Extract<UNITS, 'foot'>;
