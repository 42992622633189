import React from 'react';

import { EFilterCategories } from '@/constants/searchFilters';

import DropdownButton from '../DropdownButton/DropdownButton';
import {
  EPromotionalFilterCategories,
  useFilterPillsCtx,
} from '../FilterPillsContext/FilterPillsContext';
import HelpMeSearch from '../RentalFilters/HelpMeSearch/HelpMeSearch';
import css from './FilterPills.module.css';

interface IMenuItem {
  count?: number;
  customClass?: string;
  customIcon?: React.ReactNode;
  onClick?: () => void;
  title?: React.ReactNode;
  selected?: boolean;
  active?: boolean;
  ref?: React.RefObject<HTMLLIElement>;
  variation: EFilterCategories;
}

interface IFilterPillsProps {
  containerClass?: string;
  items: IMenuItem[];
  setFieldElement: (element: HTMLElement | null) => void;
}

const FilterPills: React.FC<IFilterPillsProps> = ({
  containerClass = 'flex',
  items,
  setFieldElement,
}) => {
  const { hideFilter } = useFilterPillsCtx();

  return (
    <ul
      className={`${containerClass} md:w-full ${css.item} flex items-center justify-start gap-1.5 md:gap-4`}
      role="menubar">
      {!hideFilter(EPromotionalFilterCategories.GUIDED_SEARCH) && <HelpMeSearch />}
      {items.map(
        (
          { title, onClick, count, customClass, customIcon, ref, selected, variation, active },
          index,
        ) => {
          return (
            <li
              ref={ref}
              key={index}
              aria-expanded={selected}
              data-testid={`${variation.toLowerCase()}-menu-item${selected ? '-selected' : ''}`}
              className={`h-full flex items-center transition-all duration-300 ${customClass} py-3 md:py-5`}
              role="menuitem">
              <DropdownButton
                onClick={() => onClick?.()}
                customIcon={customIcon}
                count={count}
                title={title}
                active={active}
                selected={selected}
                setFieldElement={setFieldElement}
              />
            </li>
          );
        },
      )}
    </ul>
  );
};

export default FilterPills;
