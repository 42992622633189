import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuTriggerState } from 'react-stately';

import { TAmenitySlug } from '@/constants/amenitiesFilter';
import { TCancelPoliciesFilters } from '@/constants/moreFilter';
import { EFilterCategories, ESearchFilters } from '@/constants/searchFilters';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { setHeaderSearchFilter } from '@/redux/modules/globalHeader';
import {
  applySearchFilter,
  GET_VEHICLE_INFO_RESPONSE,
  GET_VEHICLES_MAKE_RESPONSE,
  GET_VEHICLES_MODEL_RESPONSE,
  setSelectedFilter,
  unsetSearchFilter,
} from '@/redux/modules/search';
import { clearFormFilter, ISearchFormFilters, setFormFilter } from '@/redux/modules/searchForm';
import { TRootState } from '@/redux/rootReducer';
import { getQueryParams } from '@/redux/selectors/queryParams';
import { getSearchResultsMeta } from '@/redux/selectors/search/meta';
import { TVehicleType } from '@/services/types/search/rentals/id';
import { getParamAsString } from '@/utility/queryParams';

import FilterDropdownContainer from '../FilterDropdownContainer/FilterDropdownContainer';
import FilterModalContainer from '../FilterModal/FilterModalContainer';
import { useFilterPillsCtx } from '../FilterPillsContext/FilterPillsContext';

interface IFiltersPillsContentContainerProps {
  triggerRef: React.RefObject<HTMLElement>;
  isModalOpen: boolean;
  isDropdownOpen: boolean;
  handleDropdownDismiss: () => void;
  handleModalDismiss: () => void;
  dropdownTriggerState: MenuTriggerState;
}

export enum EDeliveryFilterStep {
  PICKUP = 1,
  DELIVERY = 2,
}

const FiltersPillsContentContainer: React.FC<IFiltersPillsContentContainerProps> = ({
  triggerRef,
  isModalOpen,
  isDropdownOpen,
  handleDropdownDismiss,
  handleModalDismiss,
  dropdownTriggerState,
}) => {
  const { isMobile } = useBreakpoint();
  const dispatch = useDispatch();
  const { isAutocompleteOpen } = useFilterPillsCtx();

  const {
    priceHistogram: { maxValue: maxPriceFromMeta },
  } = useSelector(getSearchResultsMeta);
  const searchFormFilters = useSelector<TRootState, Partial<ISearchFormFilters>>(
    state => state.searchForm.filters,
  );

  const filtersFromQuery = useSelector(getQueryParams);
  const {
    [ESearchFilters.PRICE_MIN]: pMin,
    [ESearchFilters.PRICE_MAX]: pMax,
    [ESearchFilters.DELIVERY]: deliveryFromQuery,
    [ESearchFilters.DELIVERY_STATIONARY]: deliveryStationaryFromQuery,
    [ESearchFilters.DELIVERY_ADDRESS]: deliveryAddressFromQuery,
    [ESearchFilters.FILTER_FEATURE]: featuresFromQuery,
    [ESearchFilters.SEARCH_DELIVERY_CAMPGROUND_ID]: deliveryCampgroundIdFromQuery,
    [ESearchFilters.BOUNDS_NE]: boundsNEFromQuery,
    [ESearchFilters.BOUNDS_SW]: boundsSWFromQuery,
    [ESearchFilters.ADDRESS]: addressFromQuery,
  } = filtersFromQuery;

  const [deliveryFilterStep, setDeliveryFilterStep] = useState<EDeliveryFilterStep>(
    deliveryFromQuery === 'true' ? EDeliveryFilterStep.DELIVERY : EDeliveryFilterStep.PICKUP,
  );

  const resetPageParams: Partial<Record<ESearchFilters, any>> = useMemo(
    () => ({
      [ESearchFilters.PAGE_OFFSET]: String(0),
    }),
    [],
  );

  const handlePriceFilterSubmit = useCallback(() => {
    const price = {
      [ESearchFilters.PRICE_MIN]: searchFormFilters[ESearchFilters.PRICE_MIN],
      [ESearchFilters.PRICE_MAX]: searchFormFilters[ESearchFilters.PRICE_MAX],
    };
    // TODO: move this type out of the function.
    type TPriceKey = keyof typeof price;

    const unsetPrice = Object.keys(price).filter(k => {
      const value = price[k as TPriceKey];
      return (
        typeof value === 'undefined' ||
        (k === ESearchFilters.PRICE_MAX && value && value >= maxPriceFromMeta)
      );
    });
    const setPrice = Object.keys(price).reduce<Partial<Record<TPriceKey, string>>>((acc, cur) => {
      const key = cur as TPriceKey;
      if (unsetPrice.includes(key)) return acc;
      return { ...acc, [key]: String(price[key]) };
    }, {});

    unsetPrice.length && dispatch(unsetSearchFilter(unsetPrice as ESearchFilters[]));
    !isEmpty(setPrice) &&
      dispatch(
        applySearchFilter({
          ...resetPageParams,
          ...setPrice,
        }),
      );
  }, [searchFormFilters, dispatch, resetPageParams, maxPriceFromMeta]);

  const handlePriceFilterDismiss = useCallback(() => {
    const price = {
      [ESearchFilters.PRICE_MIN]: (pMin && Number(pMin)) || undefined,
      [ESearchFilters.PRICE_MAX]: (pMax && Number(pMax)) || undefined,
    };

    type TPriceKey = keyof typeof price;

    const unsetPrice = Object.keys(price).filter(k => {
      const value = price[k as TPriceKey];
      return (
        typeof value === 'undefined' ||
        (k === ESearchFilters.PRICE_MAX && value && value >= maxPriceFromMeta)
      );
    });
    const setPrice = Object.keys(price).reduce<Partial<ISearchFormFilters>>((acc, cur) => {
      const key = cur as TPriceKey;
      if (unsetPrice.includes(key)) return acc;
      return { ...acc, [key]: String(price[key]) };
    }, {});

    unsetPrice.length && dispatch(clearFormFilter({ filters: unsetPrice as ESearchFilters[] }));
    dispatch(setSelectedFilter(null));
    !isEmpty(setPrice) &&
      dispatch(
        setFormFilter({ filters: setPrice as ISearchFormFilters, filterMethod: null }, false),
      );
  }, [dispatch, maxPriceFromMeta, pMax, pMin]);

  const handleDeliveryFilterSubmit = useCallback(() => {
    const {
      [ESearchFilters.DELIVERY]: deliveryFromStore,
      [ESearchFilters.DELIVERY_ADDRESS]: deliveryAddressFromStore,
      [ESearchFilters.DELIVERY_STATIONARY]: deliveryStationaryFromStore,
      [ESearchFilters.DELIVERY_CENTER]: deliveryCenterFromStore,
      [ESearchFilters.DELIVERY_DETAILS]: deliveryDetailsFromStore,
      [ESearchFilters.DELIVERY_QUERY]: deliveryQueryFromStore,
      [ESearchFilters.DELIVERY_LOCATION_ID]: deliveryLocationIdFromStore,
      [ESearchFilters.BOUNDS_NE]: boundsNEFromStore,
      [ESearchFilters.BOUNDS_SW]: boundsSWFromStore,
      [ESearchFilters.GEOIP_ADDRESS]: geoipFromStore,
      [ESearchFilters.SEARCH_DELIVERY_CAMPGROUND_ID]: deliveryCampgroundIdFromStore,
      [ESearchFilters.ADDRESS]: addressFromStore,
    } = searchFormFilters;

    const shouldApplySearchFilter =
      deliveryAddressFromStore ||
      deliveryStationaryFromStore ||
      deliveryCenterFromStore ||
      deliveryLocationIdFromStore ||
      deliveryQueryFromStore;

    setDeliveryFilterStep(
      deliveryFromStore ? EDeliveryFilterStep.DELIVERY : EDeliveryFilterStep.PICKUP,
    );

    if (shouldApplySearchFilter) {
      dispatch(
        applySearchFilter({
          ...resetPageParams,
          [ESearchFilters.DELIVERY]: String(deliveryFromStore),
          ...(deliveryAddressFromStore && {
            [ESearchFilters.DELIVERY_ADDRESS]: String(deliveryAddressFromStore),
          }),
          ...(deliveryCenterFromStore && {
            [ESearchFilters.DELIVERY_CENTER]: deliveryCenterFromStore,
          }),
          ...(deliveryDetailsFromStore && {
            [ESearchFilters.DELIVERY_DETAILS]: deliveryDetailsFromStore,
          }),
          ...(deliveryLocationIdFromStore && {
            [ESearchFilters.DELIVERY_LOCATION_ID]: String(deliveryLocationIdFromStore),
          }),
          ...(deliveryQueryFromStore && {
            [ESearchFilters.DELIVERY_QUERY]: String(deliveryQueryFromStore),
          }),
          // The bounds remain saved in the search form and are not only used when returning to the 'pick it up' stage.
          [ESearchFilters.BOUNDS_NE]: undefined,
          [ESearchFilters.BOUNDS_SW]: undefined,
          [ESearchFilters.DELIVERY_STATIONARY]: String(deliveryStationaryFromStore),
          [ESearchFilters.SEARCH_DELIVERY_CAMPGROUND_ID]: deliveryCampgroundIdFromStore
            ? String(deliveryCampgroundIdFromStore)
            : undefined,
        }),
      );
      dispatch(
        setFormFilter(
          {
            filters: {
              ...(boundsNEFromQuery && {
                [ESearchFilters.BOUNDS_NE]: String(boundsNEFromQuery),
              }),
              ...(boundsSWFromQuery && {
                [ESearchFilters.BOUNDS_SW]: String(boundsSWFromQuery),
              }),
              ...(addressFromQuery && {
                [ESearchFilters.ADDRESS]: String(addressFromQuery),
              }),
            },
            filterMethod: null,
          },
          true,
        ),
      );

      if (deliveryAddressFromStore) {
        dispatch(
          setHeaderSearchFilter({
            address: String(deliveryAddressFromStore),
          }),
        );
      }
    } else {
      const existsFilters = {
        ...(boundsNEFromStore && { [ESearchFilters.BOUNDS_NE]: boundsNEFromStore }),
        ...(boundsSWFromStore && { [ESearchFilters.BOUNDS_SW]: boundsSWFromStore }),
        ...(geoipFromStore && { [ESearchFilters.GEOIP_ADDRESS]: geoipFromStore }),
        ...(addressFromStore && { [ESearchFilters.ADDRESS]: addressFromStore }),
      };
      dispatch(
        unsetSearchFilter(
          [
            ESearchFilters.DELIVERY,
            ESearchFilters.DELIVERY_ADDRESS,
            ESearchFilters.DELIVERY_CENTER,
            ESearchFilters.DELIVERY_LOCATION_ID,
            ESearchFilters.DELIVERY_QUERY,
            ESearchFilters.DELIVERY_STATIONARY,
            ESearchFilters.SEARCH_DELIVERY_CAMPGROUND_ID,
            ESearchFilters.DELIVERY_DETAILS,
          ],
          existsFilters,
        ),
      );
    }
  }, [
    searchFormFilters,
    dispatch,
    resetPageParams,
    boundsNEFromQuery,
    boundsSWFromQuery,
    addressFromQuery,
  ]);

  const handleDeliveryFilterDismiss = useCallback(() => {
    // Do not dismiss if autocomplete is open
    if (isAutocompleteOpen) {
      return;
    }

    const hadDeliveryBefore = deliveryFromQuery === 'true';

    setDeliveryFilterStep(
      hadDeliveryBefore ? EDeliveryFilterStep.DELIVERY : EDeliveryFilterStep.PICKUP,
    );

    if (hadDeliveryBefore) {
      const filters: Partial<ISearchFormFilters> = {
        [ESearchFilters.DELIVERY]: hadDeliveryBefore,
        [ESearchFilters.DELIVERY_STATIONARY]: deliveryStationaryFromQuery as string,
        [ESearchFilters.DELIVERY_ADDRESS]: deliveryAddressFromQuery as string,
      };
      if (deliveryCampgroundIdFromQuery) {
        filters[ESearchFilters.SEARCH_DELIVERY_CAMPGROUND_ID] = Number(
          deliveryCampgroundIdFromQuery,
        );
      }
      dispatch(
        setFormFilter({
          filters,
          filterMethod: null,
        }),
      );
    } else {
      dispatch(
        setFormFilter({
          filters: {
            [ESearchFilters.DELIVERY]: false,
          },
          filterMethod: null,
        }),
      );

      dispatch(
        clearFormFilter({
          filters: [
            ESearchFilters.DELIVERY_STATIONARY,
            ESearchFilters.DELIVERY_ADDRESS,
            ESearchFilters.SEARCH_DELIVERY_CAMPGROUND_ID,
          ],
        }),
      );
    }
  }, [
    deliveryAddressFromQuery,
    deliveryCampgroundIdFromQuery,
    deliveryFromQuery,
    deliveryStationaryFromQuery,
    dispatch,
    isAutocompleteOpen,
    setDeliveryFilterStep,
  ]);

  const handleFiltersReset = useCallback(
    (filtersToReset: ESearchFilters[], filtersToRestore: ESearchFilters[] = []) => {
      const featuresFromQueryArray = getParamAsString(featuresFromQuery)?.split(
        ',',
      ) as TAmenitySlug[];

      // clearFormFilter will set this list as the new features list
      // check if pet_friendly and accessible are set in the query
      const featureListToKeep =
        featuresFromQueryArray?.filter(
          feature => feature === 'pet_friendly' || feature === 'accessible',
        ) || [];

      dispatch(
        clearFormFilter({
          filters: filtersToReset,
          filtersToRestore,
          filterFeatures: {
            [ESearchFilters.FILTER_FEATURE]: featureListToKeep,
          },
        }),
      );

      // Reset type filters make, model, info
      if (filtersToReset.includes(ESearchFilters.FILTER_TYPE)) {
        [
          GET_VEHICLES_MAKE_RESPONSE,
          GET_VEHICLES_MODEL_RESPONSE,
          GET_VEHICLE_INFO_RESPONSE,
        ].forEach(type => {
          dispatch({
            type,
            payload: [],
          });
        });
      }
    },
    [dispatch, featuresFromQuery],
  );

  const handleMoreFilterSubmit = useCallback(() => {
    const more = {
      [ESearchFilters.FILTER_TYPE]: searchFormFilters[ESearchFilters.FILTER_TYPE],
      [ESearchFilters.CANCEL_POLICY]: searchFormFilters[ESearchFilters.CANCEL_POLICY],
      [ESearchFilters.FILTER_FEATURE]: searchFormFilters[ESearchFilters.FILTER_FEATURE],
      [ESearchFilters.FILTER_KEYWORDS]: searchFormFilters[ESearchFilters.FILTER_KEYWORDS],
      [ESearchFilters.LENGTH_BETWEEN]: searchFormFilters[ESearchFilters.LENGTH_BETWEEN],
      [ESearchFilters.SCORE]: searchFormFilters[ESearchFilters.SCORE],
      [ESearchFilters.UNLIMITED_MILES]: searchFormFilters[ESearchFilters.UNLIMITED_MILES],
      [ESearchFilters.UNLIMITED_GENERATOR]: searchFormFilters[ESearchFilters.UNLIMITED_GENERATOR],
      [ESearchFilters.WEIGHT_GREATER]: searchFormFilters[ESearchFilters.WEIGHT_GREATER],
      [ESearchFilters.WEIGHT_LESSER]: searchFormFilters[ESearchFilters.WEIGHT_LESSER],
      [ESearchFilters.SEATBELTS]: searchFormFilters[ESearchFilters.SEATBELTS],
    };

    const make = {
      [ESearchFilters.FILTER_VEHICLE_MAKE]: searchFormFilters[ESearchFilters.FILTER_VEHICLE_MAKE],
      [ESearchFilters.FILTER_VEHICLE_MAKE_MODELS]:
        searchFormFilters[ESearchFilters.FILTER_VEHICLE_MAKE_MODELS],
      [ESearchFilters.YEAR_GREATER]: searchFormFilters[ESearchFilters.YEAR_GREATER],
      [ESearchFilters.YEAR_LESSER]: searchFormFilters[ESearchFilters.YEAR_LESSER],
    };

    type TMoreKey = keyof typeof more;
    type TMakeKey = keyof typeof make;

    const unsetMore = Object.keys(more).filter(k => typeof more[k as TMoreKey] === 'undefined');
    const setMore = Object.keys(more).reduce<Partial<Record<TMoreKey, string>>>((acc, cur) => {
      const key = cur as TMoreKey;
      return { ...acc, [key]: String(more[key]) };
    }, {});
    const setMake = Object.keys(make).reduce<Partial<Record<TMoreKey, string>>>((acc, cur) => {
      const key = cur as TMakeKey;
      return { ...acc, [key]: String(make[key]) };
    }, {});

    unsetMore.length && dispatch(unsetSearchFilter(unsetMore as ESearchFilters[]));
    (!isEmpty(setMore) || !isEmpty(setMake)) &&
      dispatch(
        applySearchFilter({
          ...resetPageParams,
          ...(isEmpty(setMore) ? {} : setMore),
          ...(isEmpty(setMake) ? {} : setMake),
        }),
      );
  }, [dispatch, resetPageParams, searchFormFilters]);

  const handleTypeFilterSubmit = useCallback(() => {
    const filterType = searchFormFilters[ESearchFilters.FILTER_TYPE];

    const filterResults = {
      [ESearchFilters.FILTER_TYPE]: Array.isArray(filterType) ? filterType.join(',') : undefined,
      [ESearchFilters.WEIGHT_LESSER]:
        searchFormFilters[ESearchFilters.WEIGHT_LESSER]?.toString() || undefined,
      [ESearchFilters.SUGGESTED_TOWING_CAPACITY]:
        searchFormFilters[ESearchFilters.SUGGESTED_TOWING_CAPACITY]?.toString() || undefined,
      [ESearchFilters.TOWING_ID]:
        searchFormFilters[ESearchFilters.TOWING_ID]?.toString() || undefined,
    };

    dispatch(
      !isEmpty(filterResults)
        ? applySearchFilter({
            ...resetPageParams,
            ...filterResults,
          })
        : unsetSearchFilter([
            ESearchFilters.FILTER_TYPE,
            ESearchFilters.WEIGHT_LESSER,
            ESearchFilters.SUGGESTED_TOWING_CAPACITY,
            ESearchFilters.TOWING_ID,
          ]),
    );
  }, [dispatch, resetPageParams, searchFormFilters]);

  const handleTypeFilterDismiss = useCallback(() => {
    const filterType = filtersFromQuery[ESearchFilters.FILTER_TYPE] as string;

    const filterResults = {
      [ESearchFilters.FILTER_TYPE]: filterType?.split(',') as TVehicleType[],
      [ESearchFilters.WEIGHT_LESSER]:
        Number(filtersFromQuery[ESearchFilters.WEIGHT_LESSER]) || undefined,
      [ESearchFilters.SUGGESTED_TOWING_CAPACITY]:
        Number(filtersFromQuery[ESearchFilters.SUGGESTED_TOWING_CAPACITY]) || undefined,
      [ESearchFilters.TOWING_ID]: Number(filtersFromQuery[ESearchFilters.TOWING_ID]) || undefined,
    };

    dispatch(setFormFilter({ filters: filterResults, filterMethod: null }));
  }, [dispatch, filtersFromQuery]);

  const handleMoreFilterDismiss = useCallback(() => {
    const filterResults = {
      [ESearchFilters.FILTER_KEYWORDS]: filtersFromQuery[ESearchFilters.FILTER_KEYWORDS]
        ? String(filtersFromQuery[ESearchFilters.FILTER_KEYWORDS])
        : undefined,
      [ESearchFilters.YEAR_GREATER]:
        Number(filtersFromQuery[ESearchFilters.YEAR_GREATER]) || undefined,
      [ESearchFilters.YEAR_LESSER]:
        Number(filtersFromQuery[ESearchFilters.YEAR_LESSER]) || undefined,
      [ESearchFilters.FILTER_VEHICLE_MAKE]: filtersFromQuery[ESearchFilters.FILTER_VEHICLE_MAKE]
        ? String(filtersFromQuery[ESearchFilters.FILTER_VEHICLE_MAKE])
        : undefined,
      [ESearchFilters.FILTER_VEHICLE_MAKE_MODELS]: filtersFromQuery[
        ESearchFilters.FILTER_VEHICLE_MAKE_MODELS
      ]
        ? String(filtersFromQuery[ESearchFilters.FILTER_VEHICLE_MAKE_MODELS])
        : undefined,
      [ESearchFilters.SCORE]: Number(filtersFromQuery[ESearchFilters.SCORE]) || undefined,
      [ESearchFilters.UNLIMITED_GENERATOR]:
        filtersFromQuery[ESearchFilters.UNLIMITED_GENERATOR] == 'true',
      [ESearchFilters.UNLIMITED_MILES]: filtersFromQuery[ESearchFilters.UNLIMITED_MILES] == 'true',
      [ESearchFilters.CANCEL_POLICY]:
        (getParamAsString(filtersFromQuery[ESearchFilters.CANCEL_POLICY])?.split(
          ',',
        ) as TCancelPoliciesFilters[]) || undefined,
      [ESearchFilters.FILTER_FEATURE]:
        (getParamAsString(filtersFromQuery[ESearchFilters.FILTER_FEATURE])?.split(
          ',',
        ) as TAmenitySlug[]) || undefined,
      [ESearchFilters.WEIGHT_GREATER]:
        Number(filtersFromQuery[ESearchFilters.WEIGHT_GREATER]) || undefined,
      [ESearchFilters.WEIGHT_LESSER]:
        Number(filtersFromQuery[ESearchFilters.WEIGHT_LESSER]) || undefined,
      [ESearchFilters.LENGTH_BETWEEN]:
        getParamAsString(filtersFromQuery[ESearchFilters.LENGTH_BETWEEN])?.split(',') || undefined,
      [ESearchFilters.SEATBELTS]: Number(filtersFromQuery[ESearchFilters.SEATBELTS]) || undefined,
    };

    dispatch(setFormFilter({ filters: filterResults, filterMethod: null }));
  }, [dispatch, filtersFromQuery]);

  const filterFunctions = useMemo(
    () => ({
      [EFilterCategories.TYPE]: {
        onReset: () =>
          handleFiltersReset([
            ESearchFilters.FILTER_TYPE,
            ESearchFilters.WEIGHT_LESSER,
            ESearchFilters.SUGGESTED_TOWING_CAPACITY,
            ESearchFilters.TOWING_ID,
          ]),
        onSubmit: handleTypeFilterSubmit,
        onDismiss: handleTypeFilterDismiss,
      },
      [EFilterCategories.PRICE]: {
        onReset: () => handleFiltersReset([ESearchFilters.PRICE_MIN, ESearchFilters.PRICE_MAX]),
        onSubmit: handlePriceFilterSubmit,
        onDismiss: handlePriceFilterDismiss,
      },
      [EFilterCategories.DELIVERY]: {
        onReset: () =>
          handleFiltersReset(
            [
              ESearchFilters.DELIVERY,
              ESearchFilters.DELIVERY_ADDRESS,
              ESearchFilters.DELIVERY_CENTER,
              ESearchFilters.DELIVERY_LOCATION_ID,
              ESearchFilters.DELIVERY_QUERY,
              ESearchFilters.DELIVERY_STATIONARY,
              ESearchFilters.DELIVERY_DETAILS,
              ESearchFilters.SEARCH_DELIVERY_CAMPGROUND_ID,
            ],
            [ESearchFilters.BOUNDS_NE, ESearchFilters.BOUNDS_SW, ESearchFilters.ADDRESS],
          ),
        onSubmit: handleDeliveryFilterSubmit,
        onDismiss: handleDeliveryFilterDismiss,
      },
      [EFilterCategories.MORE]: {
        onReset: () =>
          handleFiltersReset([
            ESearchFilters.CANCEL_POLICY,
            ESearchFilters.FILTER_FEATURE,
            ESearchFilters.FILTER_KEYWORDS,
            ESearchFilters.LENGTH_BETWEEN,
            ESearchFilters.SCORE,
            ESearchFilters.UNLIMITED_MILES,
            ESearchFilters.UNLIMITED_GENERATOR,
            ESearchFilters.WEIGHT_GREATER,
            ESearchFilters.WEIGHT_LESSER,
            ESearchFilters.FILTER_VEHICLE_MAKE,
            ESearchFilters.FILTER_VEHICLE_MAKE_MODELS,
            ESearchFilters.YEAR_GREATER,
            ESearchFilters.YEAR_LESSER,
            ESearchFilters.SEATBELTS,
          ]),
        onSubmit: handleMoreFilterSubmit,
        onDismiss: handleMoreFilterDismiss,
      },
    }),
    [
      handleDeliveryFilterDismiss,
      handleDeliveryFilterSubmit,
      handleFiltersReset,
      handleMoreFilterDismiss,
      handleMoreFilterSubmit,
      handlePriceFilterDismiss,
      handlePriceFilterSubmit,
      handleTypeFilterDismiss,
      handleTypeFilterSubmit,
    ],
  );

  return (
    <>
      {/* Renders modal with filter content and submit/reset buttons on desktop and mweb */}
      <FilterModalContainer
        isModalOpen={isModalOpen}
        handleDismiss={handleModalDismiss}
        filterFunctions={filterFunctions}
        deliveryFilterStep={deliveryFilterStep}
        setDeliveryFilterStep={setDeliveryFilterStep}
      />

      {/* Renders dropdown container for some of the filters on desktop */}
      {!isMobile && (
        <FilterDropdownContainer
          onDismiss={handleDropdownDismiss}
          triggerState={dropdownTriggerState}
          show={isDropdownOpen}
          triggerRef={triggerRef}
          filterFunctions={filterFunctions}
        />
      )}
    </>
  );
};

export default FiltersPillsContentContainer;
