import React from 'react';

import { IQuoteDeliverableCampground } from '@/services/types/core/quotes';
import { ICampgroundData } from '@/services/types/search/campgrounds/id';

import { CampgroundCard } from './CampgroundCard';

interface ICampgroundListProps {
  campgroundList?: ICampgroundData[] | IQuoteDeliverableCampground[];
  handleSelectedCampground: (id: string) => void;
  selectedCampgroundId?: number;
}

export const CampgroundList = ({
  campgroundList,
  selectedCampgroundId,
  handleSelectedCampground,
}: ICampgroundListProps) => {
  if (!campgroundList?.length) {
    return null;
  }

  return (
    <div className="mb-6">
      <div className="flex flex-col gap-4">
        {campgroundList.map(
          ({
            name,
            id,
            location,
            unavailable,
            locale,
            lowest_price,
            primary_image_url,
            images,
            description,
            odn_id,
            score,
            reviews_num,
          }) => (
            <CampgroundCard
              name={name}
              location={location}
              unavailable={unavailable}
              currency={locale.base_currency}
              lowestPrice={lowest_price}
              key={id}
              handleSelectedCampground={handleSelectedCampground}
              isSelected={selectedCampgroundId === id}
              imageUrl={primary_image_url}
              id={id}
              images={images}
              description={description}
              odnId={odn_id}
              score={score}
              reviewsNum={reviews_num}
            />
          ),
        )}
      </div>
    </div>
  );
};
